import * as Styles from '../HowItWorksCard.css'

const useContainerStyles = ({
  variant = 'odd',
}: {
  variant: 'odd' | 'even'
}) => {
  const classes = [Styles.container]
  switch (variant) {
    case 'odd':
      classes.push(Styles.containerOdd)
      break
    case 'even':
      classes.push(Styles.containerEven)
      break

    default:
      const _exhaustiveCheck: never = variant
      return _exhaustiveCheck
  }
  return {
    className: classes.join(' '),
  } as const
}

export default useContainerStyles
