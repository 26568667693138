import { Box } from '@chakra-ui/core'
import dayjs from 'dayjs'
import { rem } from 'design'
import Hero from 'design/components/Hero/Hero'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { graphql, useStaticQuery } from 'gatsby'
import { has } from 'lodash'
import qs from 'qs'
import React from 'react'
import * as Yup from 'yup'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import { CMS } from '~/cms/types'
import { MODULE_SOCIAL_CARDS } from '~/cms/validation'
import CollapsibleFooter from '~/components/CollapsibleFooter/CollapsibleFooter'
import ModuleSocialCards from '~/components/ModuleSocialCards/ModuleSocialCards'
import { TEST_ID } from '~/constants/cypress'
import useCmsVariant from '~/hooks/useCmsVariant'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { TRY_BB_URL } from '~/routes/constants'
import ExplorePossibilities from '~/sections/ExplorePossibilities/ExplorePossibilities'
import HowItWorks from '~/sections/HowItWorks/HowItWorks'
import WhatWeDeliver from '~/sections/WhatWeDeliver/WhatWeDeliver'
import { setCookie } from '~/utils/cookie'
import { cleanJsonStrings } from '~/utils/unicode'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'
import useSetCartOffer from '~/hooks/useSetCartOffer'

const VALIDATION_SCHEMA = {
  content: Yup.object().shape({
    moduleSocialCards: MODULE_SOCIAL_CARDS.required(),
  }),
}

export default function Homepage() {
  const showToast = React.useContext(ToastContext)

  React.useEffect(() => {
    if (location.search) {
      const queryParams = qs.parse(location.search.slice(1))
      queryParams.impId &&
        setCookie('impId', queryParams.impId.toString(), dayjs().add(30, 'day'))
      queryParams.irclickid &&
        setCookie(
          'irclickid',
          queryParams.irclickid.toString(),
          dayjs().add(30, 'day')
        )
      has(queryParams, 'loggedout') &&
        showToast('custom', {
          children: `We’re sorry to see you go. Log in at any time to resume your membership.`,
        })
    }
  }, [showToast])

  // TODO: Use the SEO fragment here
  const { pageData } = cleanJsonStrings(
    useStaticQuery<{ pageData: any }>(graphql`
      query HomepageStaticQuery {
        pageData: contentfulProtectedPage(pageId: { eq: "home-page" }) {
          ...ProtectedPage
        }
      }
    `)
  )

  const { header } = useProtectedPage(pageData, null, {
    shouldReplaceHeader: false,
  })

  try {
    Yup.object().shape(VALIDATION_SCHEMA).validateSync(pageData)
  } catch (err) {
    throw new Error('Essential page data is missing')
  }

  const socialCards = pageData?.content?.moduleSocialCards?.cards?.map(
    ({ name, avatar, image, text, id }) => {
      return {
        name,
        avatar,
        image,
        text,
        id,
      }
    }
  )

  const HeroVariant = pageData?.content?.hero
    ? useCmsVariant<CMS.Hero>(pageData?.content?.hero)
    : null

  const {
    imageMobile,
    imageDesktop,
    cta: heroCta,
    textContent,
    subhead,
    textColor,
  } = HeroVariant

  const ctaText = heroCta ? useCmsVariant<CMS.CTA>(heroCta) : null

  const { cartDealUrl } = useSetCartOffer()
  const [shouldBypassGetStarted] = useOptimizelyExperiment(
    'join-586__bypassing-get-started'
  ) as [JOIN_586_VARIANTS, null, null]

  return (
    <UnauthenticatedLayout
      cta={header?.cta?.content}
      data-cy={TEST_ID.HOMEPAGE}
    >
      <Hero
        cta={ctaText?.content}
        ctaHref={
          shouldBypassGetStarted &&
          shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
            ? cartDealUrl
            : TRY_BB_URL
        }
        data-where="homepage-hero"
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        subhead={subhead}
        testId={TEST_ID.HOMEPAGE_HERO}
        textColor={textColor}
        textContent={textContent}
      />
      <WhatWeDeliver />
      <HowItWorks
        cartDealUrl={cartDealUrl}
        shouldBypassGetStarted={shouldBypassGetStarted}
      />
      <ExplorePossibilities
        cartDealUrl={cartDealUrl}
        shouldBypassGetStarted={shouldBypassGetStarted}
      />
      <Box minHeight={rem(716)}>
        <ModuleSocialCards
          cards={socialCards}
          data-where="social-proof"
          id="social-cards"
        />
      </Box>
      {pageData?.content?.collapsibleFooter ? (
        <CollapsibleFooter
          collapsibleFooter={pageData?.content?.collapsibleFooter}
        />
      ) : null}
    </UnauthenticatedLayout>
  )
}
