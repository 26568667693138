import { Modal, ModalContent, ModalDialog } from 'design/components/Modal/Modal'
import React from 'react'
import { reduceMicrocopy, reduceResourceSet } from '~/cms/microcopy/reducers'
import { CMS } from '~/cms/types'
import DealContent from './DealContent'

const CollapsibleFooter = ({
  collapsibleFooter,
  isExpanded = false,
}: {
  collapsibleFooter: CMS.CollapsibleFooter
  isExpanded?: boolean
}) => {
  const { resources, ctas } = reduceResourceSet(
    collapsibleFooter?.footerText?.resources
  )

  const { eyebrow, title, body } = reduceMicrocopy(resources, {
    eyebrow: 'collapsibleFooter.eyebrow',
    title: 'collapsibleFooter.title',
    body: 'collapsibleFooter.body',
  })

  const footerImage = collapsibleFooter?.footerImage?.fluid

  const shouldRenderCollapsibleFooter = React.useMemo(() => {
    return eyebrow && title && body && ctas[0] && footerImage
  }, [eyebrow, title, body, ctas, footerImage])

  const [isDealExpanded, setIsDealExpanded] = React.useState(isExpanded)
  const [isBannerDirty, setIsBannerDirty] = React.useState(false)

  const modalChevron = React.useRef(null)
  const footerChevron = React.useRef(null)

  function toggleModal() {
    setIsDealExpanded(!isDealExpanded)
    setIsBannerDirty(true)
  }

  React.useEffect(() => {
    if (!isBannerDirty) return

    isDealExpanded
      ? modalChevron.current.focus()
      : footerChevron.current.focus()
  }, [isDealExpanded, isBannerDirty])

  function renderVariant(isExpanded) {
    if (isExpanded) {
      return (
        <Modal
          onClose={() => {
            toggleModal()
          }}
          //@ts-ignore
          position="relative"
          showCloseButton={false}
          useContainer={false}
          zIndex="1400"
        >
          <ModalDialog>
            <ModalContent>
              <DealContent
                body={body}
                chevronRef={modalChevron}
                cta={ctas[0]}
                eyebrow={eyebrow}
                image={footerImage}
                isDealExpanded={isDealExpanded}
                title={title}
                toggleModal={toggleModal}
              />
            </ModalContent>
          </ModalDialog>
        </Modal>
      )
    } else {
      return (
        <DealContent
          body={body}
          chevronRef={footerChevron}
          cta={ctas[0]}
          eyebrow={eyebrow}
          image={footerImage}
          isDealExpanded={isDealExpanded}
          title={title}
          toggleModal={toggleModal}
        />
      )
    }
  }

  return (
    <>
      {shouldRenderCollapsibleFooter ? (
        <>{renderVariant(isDealExpanded)}</>
      ) : null}
    </>
  )
}

export default CollapsibleFooter
