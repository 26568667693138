import { HeroContainer, HeroContent, Image } from '../Hero.css'
import type {
  HeroComponentProps,
  HeadlineDefinition,
  HeroTheme,
} from '../Hero.types'
/**
 * useHeroTheme is intended to provide the proper styling
 * attributes to the Hero, for the elements based on the theme
 * (light/dark) and the markup - this also returns currently
 * hard coded variants and components for the hero, so that its
 * easily extendable in the future for use of this hero outside
 * the top module of the page, or experimenting with different
 * Text variants.
 */
const useHeroTheme = (
  textColor: HeroComponentProps['textColor']
): HeroTheme => {
  const TEXT_CONTENT: HeadlineDefinition = {
    component: 'h1',
    variant: 'DisplayOne',
  }

  const SUBHEAD: HeadlineDefinition = {
    component: 'h2',
    variant: 'H3Bold',
  }

  switch (textColor) {
    case 'Light':
      return {
        imageProps: { className: Image },
        containerProps: { className: HeroContainer.light },
        contentProps: { className: HeroContent.light },
        headlineProps: {
          ...TEXT_CONTENT,
          color: 'white',
        },
        subheadProps: {
          ...SUBHEAD,
          color: 'white',
        },
      }
    case 'Dark':
      return {
        imageProps: { className: Image },
        containerProps: { className: HeroContainer.dark },
        contentProps: { className: HeroContent.dark },
        headlineProps: {
          ...TEXT_CONTENT,
          color: 'black',
        },
        subheadProps: {
          ...SUBHEAD,
          color: 'black',
        },
      }
    default:
      const _exhaustiveCheck: never = textColor
      throw new Error(`Unimplemented case, useHeroTheme: ${_exhaustiveCheck}`)
  }
}

export default useHeroTheme
