import { Box, Flex } from '@chakra-ui/core'
import { Global, rem } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import {
  H3,
  LinkInternal,
  Subhead,
} from 'design/components/Typography/Typography'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { TRY_BB_URL } from '~/routes/constants'
import * as Styled from './CollapsibleFooter.styles'
import { IconChevronDown, IconChevronUp } from '@butcherbox/freezer'

const DealContent = ({
  isDealExpanded,
  toggleModal,
  chevronRef,
  cta,
  eyebrow,
  title,
  body,
  image,
}: {
  isDealExpanded: boolean
  toggleModal: () => void
  chevronRef: any
  cta: string
  eyebrow: string
  title: string
  body: string
  image: any
}) => {
  const expandedHeights = {
    base: 280,
    tablet: 250,
    desktop: 335,
  }

  const closedHeights = {
    base: 160,
    tablet: 120,
    desktop: 121,
  }
  const pyExpanded = 16
  const pyClosed = 8

  return (
    <Flex
      backgroundColor="white"
      borderTopColor="bb.spicedCrimson"
      borderTopWidth={rem(4)}
      bottom="0"
      cursor="pointer"
      data-cy={TEST_ID.HOMEPAGE_COLLAPSIBLE_FOOTER}
      justifyContent="center"
      maxHeight={{
        base: isDealExpanded
          ? rem(expandedHeights.base)
          : rem(closedHeights.base),
        tablet: isDealExpanded
          ? rem(expandedHeights.tablet)
          : rem(closedHeights.tablet),
        desktop: isDealExpanded
          ? rem(expandedHeights.desktop)
          : rem(closedHeights.desktop),
      }}
      onClick={() => {
        toggleModal()
      }}
      position="fixed"
      px={{ base: rem(25), desktop: rem(79) }}
      width="100%"
      zIndex={1099}
    >
      {' '}
      {/* This global style is required to give sufficient padding between the sticky footer and the real footer. */}
      <Global
        styles={{
          '#footer-terms-links': {
            '@media(max-width: 1280px)': {
              paddingBottom: rem(60),
            },
            '@media(max-width: 768px)': {
              paddingBottom: rem(138),
            },
          },
        }}
      />
      <Flex
        flexDirection={{
          base: 'column',
          desktop: 'row',
        }}
        justifyContent="center"
        width="100%"
      >
        {isDealExpanded && (
          <Box
            display={{ desktop: 'block', base: 'none' }}
            flex="0 1 auto"
            position="relative"
          >
            <Styled.ImageBottom
              fluid={image}
              style={{
                height: '',
                position: '',
              }}
            />
          </Box>
        )}
        <Flex
          alignItems="center"
          flex="0 1 auto"
          flexDirection="column"
          justifyContent={{
            desktop: 'center',
            base: isDealExpanded ? 'start' : 'center',
          }}
          py={isDealExpanded ? rem(pyExpanded) : rem(pyClosed)}
          textAlign={{
            base: 'center',
            desktop: 'center',
          }}
        >
          <Subhead as="h2" color="bb.spicedCrimson" mb={rem(8)}>
            {eyebrow}
          </Subhead>
          <Styled.DealTitle as="h3">{title}</Styled.DealTitle>
          {isDealExpanded && (
            <>
              <H3
                as="h4"
                lineHeight={rem(20)}
                maxWidth={rem(484)}
                mb={rem(16)}
                mt={rem(4)}
              >
                {body}
              </H3>
              <LargeButtonPrimary
                alignSelf="center"
                as={LinkInternal}
                data-cy={TEST_ID.HOMEPAGE_COLLAPSIBLE_FOOTER_CTA}
                href={TRY_BB_URL}
                width={rem(327)}
              >
                {cta}
              </LargeButtonPrimary>
            </>
          )}
        </Flex>
      </Flex>
      <Box
        aria-expanded={isDealExpanded}
        aria-label="Expand for details on the offer. Collapse to hide."
        as="button"
        cursor="pointer"
        onClick={() => {
          toggleModal()
        }}
        position="absolute"
        ref={chevronRef}
        right={rem(20)}
        top={rem(10)}
      >
        {isDealExpanded ? (
          <IconChevronUp customColor={{ base: 'spicedCrimson' }} size="text" />
        ) : (
          <IconChevronDown
            customColor={{ base: 'spicedCrimson' }}
            size="text"
          />
        )}
      </Box>
    </Flex>
  )
}

export default DealContent
