import * as Yup from 'yup'

export const FIXED_IMAGE = Yup.object().shape({
  base64: Yup.string().required(),
  height: Yup.number().required(),
  src: Yup.string().required(),
  srcSet: Yup.string().required(),
  srcSetWebp: Yup.string().required(),
  srcWebp: Yup.string().required(),
  width: Yup.number().required(),
})

export const FLUID_IMAGE = Yup.object().shape({
  aspectRatio: Yup.number().required(),
  base64: Yup.string().required(),
  sizes: Yup.string().required(),
  src: Yup.string().required(),
  srcSet: Yup.string().required(),
  srcSetWebp: Yup.string().required(),
  srcWebp: Yup.string().required(),
})

export const SOCIAL_CARD = Yup.object().shape({
  avatar: Yup.object()
    .shape({
      fixed: FIXED_IMAGE.required(),
    })
    .required(),
  id: Yup.string().required(),
  image: Yup.object()
    .shape({
      fluid: FLUID_IMAGE.required(),
    })
    .required(),
  name: Yup.string().required(),
  text: Yup.object()
    .shape({
      raw: Yup.string().required(),
    })
    .required(),
})

export const MODULE_SOCIAL_CARDS = Yup.object().shape({
  cards: Yup.array().of(SOCIAL_CARD).length(3).required(),
})
