import { Grid, Flex } from '@chakra-ui/core'
import { styled, rem } from 'design'

const GRID_ITEM_SIZE = rem(300)

export const CardGrid = styled(Grid)`
  grid-gap: ${rem(36)};
  margin-bottom: ${rem(40)};
  grid-auto-columns: ${GRID_ITEM_SIZE};
  grid-auto-flow: row;
  @media screen and (min-width: ${rem(1080)}) {
    grid-auto-flow: column;
  }
`

export const ListItemContainer = styled(Flex)`
  align-items: center;
  background: white;
  box-shadow: ${(p) => p.theme.shadows.shadow};
  flex-direction: column;
  height: ${GRID_ITEM_SIZE};
  padding: 0 ${rem(24)};
`
