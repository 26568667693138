import { styled, rem } from 'design'
import { HeadlineGothamSmaller } from 'design/components/Typography/Headlines'
import { ITypography } from 'design/components/Typography/Typography'
import GatsbyImage from 'gatsby-image'

export const ImageBottom = styled(GatsbyImage)`
  right: -10px;
  position: absolute;
  width: 365px;
  height: 324px;
  top: -55px;
`

export const DealTitle = styled<ITypography>(HeadlineGothamSmaller)`
  color: ${(p) => p.theme.colors.bb.spicedCrimson};
  line-height: 1;

  @media (max-width: 760px) {
    font-size:  ${rem(24)}};
  }
`
