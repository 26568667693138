import Img from 'gatsby-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import useCmsAtomicImageSet from '~/hooks/useCmsAtomImageSet'
import useCmsImageSet from '~/hooks/useCmsImageSet'
import type * as Types from './ImageSet.types'
/**
 * An image set returns a gatsby-image that is rendered
 * from the contentful ImageSet content type.
 */
export default function ImageSet({
  imageSet,
  imageSetSizes = { d: 1, t: 1, m: 1 },
  alt,
  ...props
}: Types.ImageSetProps) {
  const sources = useCmsImageSet(imageSet, imageSetSizes)
  const atomSources = useCmsAtomicImageSet(imageSet)
  switch (imageSet.__typename) {
    case 'ContentfulAtomImageSet':
      return (
        <GatsbyImage
          alt={alt ? alt : imageSet.imageAlt}
          image={atomSources}
          {...props}
        />
      )
    case 'ContentfulImageSet':
      return (
        <Img alt={alt ? alt : imageSet.imageAlt} fluid={sources} {...props} />
      )
    default:
      break
  }
}
