import {
  LinkExternal,
  LinkInternal,
} from 'design/components/Typography/Typography'
import React from 'react'
import type * as Types from './HowItWorksCard.types'
import { Text, Box, Lockup, treats, IconArrowRight } from '@butcherbox/freezer'
import * as Styles from './HowItWorksCard.css'
import useContainerStyles from './hooks/useContainerStyles'
import ImageSet from '~/components/ImageSet/ImageSet'

/**
 * The How It Works Card renders a numbered card and photo combination as seen on the homepage
 */
function HowItWorksCard({
  imageSet,
  title,
  text,
  linkDestination,
  linkText,
  isExternal = false,
  number = 1,
  align = 'left',
}: Types.HowItWorksCardProps) {
  const variant = align === 'left' ? 'even' : 'odd'
  return (
    <Box
      component="li"
      {...useContainerStyles({ variant })}
      data-testid={`HIWC-${number}`}
      key={title}
    >
      <ImageSet
        className={Styles.Image}
        imageSet={imageSet}
        imageSetSizes={{ d: 0.5, t: 0.5, m: 1 }}
      />
      <Box className={Styles.textBox}>
        <Lockup>
          <Text
            component="h2"
            data={{ testid: `HIWC-${number}-hl1` }}
            variant="H1Bold"
          >
            <span className={treats({ color: 'spicedCrimson' })}>{number}</span>{' '}
            {title}
          </Text>

          <Text
            component="h3"
            data={{ testid: `HIWC-${number}-hl2` }}
            variant="H3Regular"
          >
            {text}
          </Text>

          {isExternal ? (
            <LinkExternal
              data-testid={`HIWC-${number}-a`}
              href={linkDestination}
              target="_self"
              width="100%"
            >
              <Text component="h3" variant="H3Bold">
                {linkText}{' '}
                <IconArrowRight
                  display="inline"
                  size="text"
                  variant="inherit"
                />
              </Text>
            </LinkExternal>
          ) : (
            <LinkInternal
              data-testid={`HIWC-${number}-a`}
              href={linkDestination}
              width="100%"
            >
              <Text component="h3" variant="H3Bold">
                {linkText}{' '}
                <IconArrowRight
                  display="inline"
                  size="text"
                  variant="inherit"
                />
              </Text>
            </LinkInternal>
          )}
        </Lockup>
      </Box>
    </Box>
  )
}

export default HowItWorksCard
