/**
 * preventHyphenBreak appends the unicode no-break character
 * after dashes to prevent the browser from breaking the line
 * on that character
 */
export const preventHyphenBreak = (text: string): string => {
  if (!text || typeof text !== 'string') {
    return ''
  }

  return text.replace(/\-/gm, '-\u2060') // non-breaking character
}
