import { CMS } from '~/cms/types'

/**
 * Destructures Contentful entities from the resources of a Resource Set
 * into entity types
 */
export function reduceResourceSet(data: CMS.Resources) {
  const ret: {
    resources: CMS.Resource[]
    disclaimers: string[]
    ctas: CMS.CTA['content'][]
  } = {
    resources: [],
    disclaimers: [],
    ctas: [],
  }

  // TODO: refactor to use __typename
  for (const item of data) {
    if ('key' in item && 'value' in item) {
      ret.resources.push(item)
    } else if ('body' in item && !!item.body?.body) {
      ret.disclaimers.push(item.body.body)
    } else if ('content' in item) {
      ret.ctas.push(item.content)
    } else if (process.env.NODE_ENV !== 'production') {
      throw new Error(`Unexpected Resource Set data: ${JSON.stringify(item)}`)
    }
  }

  return ret
}

/**
 * Parses microcopy Resources into component properties
 *
 * @param data Graphql query result data from a Contentful ResourceSet
 * @param propSchema A mapping from component properties to ResourceSet keys
 * @example
 * const foo = parse<{ header: string }>(
 *   [
 *     {
 *       key: 'optin.header',
 *       value: 'header',
 *       id: 'foo',
 *     },
 *   ],
 *   { header: 'optin.header' }
 * )
 */
export function reduceMicrocopy<Props extends Object>(
  data: CMS.Resource[],
  propSchema: Record<keyof Props, CMS.ResourceKey>
) {
  const dataMap = data.reduce<
    Partial<Record<CMS.ResourceKey, CMS.ResourceValue>>
  >(
    (prev, { key, value }) => ({
      [key as CMS.ResourceKeyOptin]: value,
      ...prev, // do not overwrite already-set props
    }),
    {}
  )

  const propData = Object.entries(propSchema).reduce<
    Partial<Record<keyof Props, CMS.ResourceValue>>
  >(
    (prev, [propName, resourceKey]) => ({
      ...prev,
      [propName]: dataMap[resourceKey],
    }),
    {}
  )

  return propData
}
