import React, { ReactElement } from 'react'
import { Flex, FlexProps } from '@chakra-ui/core'
import { CardGrid, ListItemContainer } from './WhatWeDeliver.styles'
import {
  TwoColorSVGProps,
  IconProps,
  IconQuality,
  IconFlexible,
  Text,
  Box,
  rem,
  IconValue,
  Lockup,
  ButtonLink,
} from '@butcherbox/freezer'

type ListItem = {
  renderIcon: () => ReactElement<IconProps>
  title: string
  text: string
}

const sharedIconProps: IconProps<TwoColorSVGProps> = {
  size: 'regular',
  customColor: { base: 'slate', accent: 'spicedCrimson' },
  display: 'inline',
  verticalAlign: 'top',
  marginBottom: 20,
  marginTop: 60,
}

const ListItems: ListItem[] = [
  {
    renderIcon: () => <IconQuality {...sharedIconProps} />,
    title: 'High-quality Meat',
    text:
      '100% grass-fed beef, free-range organic chicken, humanely raised pork, and wild-caught seafood.',
  },
  {
    renderIcon: () => <IconValue {...sharedIconProps} />,
    title: 'Unbeatable Value',
    text:
      'Get a range of high-quality cuts, from ground beef to filet mignon, at an amazing value.',
  },
  {
    renderIcon: () => <IconFlexible {...sharedIconProps} />,
    title: 'Complete Flexibility',
    text:
      'Shipping is always FREE, we deliver to your door on your schedule, and you can cancel anytime.',
  },
]

export default function WhatWeDeliver(props: FlexProps) {
  return (
    <Flex
      alignItems="center"
      bg="bb.ivory"
      data-where="what-we-deliver"
      direction="column"
      height="auto"
      px={{ base: rem(24), desktop: rem(229) }}
      py={{ base: rem(56), desktop: rem(91) }}
      textAlign="center"
      {...props}
    >
      <Box style={{ maxWidth: rem(768) }}>
        <Lockup>
          <Text variant="DisplayTwo">What We Deliver</Text>
          <Text marginBottom={40} variant="H3Regular">
            When you join us, you're joining a community focused on caring about
            animals and our planet, improving livelihoods for farmers, and
            sharing better meals together.
          </Text>
        </Lockup>
      </Box>

      <CardGrid as="ul">
        {ListItems.map(({ renderIcon, title, text }) => {
          return (
            <ListItemContainer as="li" key={title}>
              {renderIcon()}
              <Text marginBottom={12} variant="Subhead1">
                <strong>{title}</strong>
              </Text>
              <Text color={'slate'} marginBottom={12} variant="Body1Regular">
                {text}
              </Text>
            </ListItemContainer>
          )
        })}
      </CardGrid>
      <ButtonLink href={'/how-it-works'} size="standard">
        View Our Boxes
      </ButtonLink>
    </Flex>
  )
}
