import type { FlexProps } from '@chakra-ui/core'
import { Box, Flex } from '@chakra-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { CTA_UNAUTHENTICATED_DEFAULT } from '~/data/constants'
import { TRY_BB_URL } from '~/routes/constants'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'
import type * as Types from './HowItWorks.types'
import HowItWorksCard from './HowItWorksCard'
import { Text, Lockup, rem } from '@butcherbox/freezer'

const Sections: Omit<Types.HowItWorksSectionProps, 'altText'>[] = [
  {
    imageKey: 'one',
    title: 'We source',
    text:
      'We source our meat and seafood from partners with the highest standards for quality.',
    linkText: 'Learn about our sourcing',
    linkDestination: '/sourcing',
  },
  {
    imageKey: 'two',
    title: 'You choose',
    text:
      'Choose from 4 curated boxes or create your own custom box. Select a delivery frequency that meets your needs.',
    linkText: 'View box options',
    linkDestination: '/how-it-works',
  },
  {
    imageKey: 'three',
    title: 'We deliver',
    text:
      'Your order ships for free, frozen for freshness and packed in an eco-friendly box.',
    linkText: 'See more about our boxes',
    linkDestination: '/how-it-works',
  },
  {
    imageKey: 'four',
    title: 'You enjoy!',
    text:
      'High-quality meat delivered to your door means more time for amazing meals together',
    linkText: CTA_UNAUTHENTICATED_DEFAULT,
    linkDestination: TRY_BB_URL,
    isExternal: false,
  },
]

type HowItWorksProps = {
  shouldBypassGetStarted?: JOIN_586_VARIANTS
  cartDealUrl?: string
}

export default function HowItWorks(props: FlexProps & HowItWorksProps) {
  const data = useStaticQuery(graphql`
    query {
      one: contentfulImageSet(uniqueId: { eq: "how_it_works_one" }) {
        ...ImageSet
      }
      two: contentfulImageSet(uniqueId: { eq: "how_it_works_two" }) {
        ...ImageSet
      }
      three: contentfulImageSet(uniqueId: { eq: "how_it_works_three" }) {
        ...ImageSet
      }
      four: contentfulImageSet(uniqueId: { eq: "how_it_works_four" }) {
        ...ImageSet
      }
    }
  `)

  const { shouldBypassGetStarted, cartDealUrl } = props

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      data-where="how-it-works"
      direction="column"
      height="auto"
      pt={{ base: rem(56), desktop: rem(91) }}
      px={{ base: rem(16), desktop: rem(209) }}
      textAlign="center"
      {...props}
    >
      <Lockup marginBottom={40}>
        <Text variant="DisplayTwo">How it Works</Text>
        <Text variant="H3Regular">
          We make it easy to feed your family the best.
        </Text>
      </Lockup>

      <Box as="ul" display="contents">
        {Sections.map(
          (
            {
              imageKey,
              title,
              text,
              linkDestination,
              linkText,
              isExternal = false,
            },
            idx
          ) => (
            <HowItWorksCard
              align={idx % 2 === 0 ? 'left' : 'right'}
              imageSet={data[imageKey]}
              isExternal={isExternal}
              key={idx}
              linkDestination={
                shouldBypassGetStarted &&
                shouldBypassGetStarted ===
                  JOIN_586_VARIANTS?.BYPASS_GET_STARTED &&
                linkDestination === TRY_BB_URL
                  ? cartDealUrl
                  : linkDestination
              }
              linkText={linkText}
              number={idx + 1}
              text={text}
              title={title}
            />
          )
        )}
      </Box>
    </Flex>
  )
}
