import { styled } from 'design'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { CTA_UNAUTHENTICATED_DEFAULT } from '~/data/constants'
import { TRY_BB_URL } from '~/routes/constants'
import * as Styles from './ExplorePossibilities.css'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { Link } from 'gatsby'
import { Box, ButtonLink, Text } from '@butcherbox/freezer'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'

export default function ExplorePossibilities({
  shouldBypassGetStarted,
  cartDealUrl,
}: {
  shouldBypassGetStarted?: JOIN_586_VARIANTS
  cartDealUrl?: string
}) {
  const data = useStaticQuery(graphql`
    query {
      explore: contentfulImageSet(uniqueId: { eq: "explore_possibilities" }) {
        ...ImageSet
      }
    }
  `)

  return (
    <Box
      className={Styles.explorePossibilities}
      data-where="explore-possibilities"
    >
      <Box className={Styles.contentWrap}>
        <Box className={Styles.header}>
          <Text variant="DisplayTwo">Explore the Possibilities</Text>
        </Box>
        <ExploreImage
          alt="A ButcherBox on a kitchen counter, with arrows pointing to box contents including: Free-range organic chicken, 100% grass-fed, grass-finished beef, Wild-caught seafood, and humanely raised pork"
          aria-label="A ButcherBox on a kitchen counter, with arrows pointing to box contents including: Free-range organic chicken, 100% grass-fed, grass-finished beef, Wild-caught seafood, and humanely raised pork"
          // TODO: Adapt this to the pork claims copy changes in WEB-1636 in a way that meets accessibility requirements.
          imageSet={data.explore}
        />
        <Box className={Styles.buttonWrap}>
          <ButtonLink
            component={Link}
            target="_self"
            to={
              shouldBypassGetStarted &&
              shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
                ? cartDealUrl
                : TRY_BB_URL
            }
          >
            {CTA_UNAUTHENTICATED_DEFAULT}
          </ButtonLink>
        </Box>
      </Box>
    </Box>
  )
}

export const ExploreImage = styled(ImageSetBackground)`
  width: 100%;
  padding-top: 153%;
  @media (min-width: 500px) {
    padding-top: 75%;
  }
`
