import { ButtonLink, Lockup, Text } from '@butcherbox/freezer'
import { makeSafeVariants } from 'design/image-utils'
import { Link as GatsbyLink } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { preventHyphenBreak } from '~/cms/utils/text'
import type { HeroComponentProps } from './Hero.types'
import useHeroTheme from './hooks/useHeroTheme'

/**
 * This component is used on the top of the homepage
 */
export default function Hero({
  imageDesktop,
  imageMobile,
  textContent,
  subhead,
  testId,
  cta,
  ctaHref,
  textColor = 'Dark',
  imageAlt,
  ...props
}: HeroComponentProps) {
  const images = makeSafeVariants({ imageMobile, imageDesktop })

  // Determine Style Variants
  const theme = useHeroTheme(textColor)

  return (
    <BackgroundImage
      aria-label={imageAlt}
      critical={true}
      data-cy={testId}
      fadeIn={false}
      fluid={images}
      style={{
        /* Required when styling a BackgroundImage */
        backgroundSize: '',
        backgroundPosition: '',
      }}
      {...theme.imageProps}
      {...props}
    >
      <div {...theme.containerProps}>
        <div {...theme.contentProps}>
          <Lockup>
            <Text {...theme.headlineProps}>
              {preventHyphenBreak(textContent)}
            </Text>
            {!!subhead ? <Text {...theme.subheadProps}>{subhead}</Text> : null}
            {cta ? (
              <>
                <ButtonLink
                  component={GatsbyLink}
                  data={{ what: 'button' }}
                  target="_self"
                  to={ctaHref}
                >
                  {cta}
                </ButtonLink>
              </>
            ) : null}
          </Lockup>
        </div>
      </div>
    </BackgroundImage>
  )
}
